<template>
  <v-app id="blockchain">
    <v-toolbar dark color="blue darken-2">
      <v-toolbar-title class="white--text">Docline Blockchain</v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 lg7>
            <v-card class="elevation-1 pa-3 max-width: 800px">
              <v-card-text>
                <div class="layout column align-center">
                  <img src="https://docline.es/fotos/docline-logo.png" alt="docline" width="170" />
                  <h4 style="margin-top:25px;margin-bottom: -10px;">
                    Comprobación de Certificado de Videconsulta
                  </h4>
                  <h4 style="margin-bottom: 5px;">
                    Mediante tecnología Blockchain
                  </h4>
                </div>
                <hr class="separator" />
                <v-form>
                  <v-layout wrap>
                    <v-flex xs12 sm6 class="search_hash">
                      <div class="layout column align-center hidden-xs-only">
                        <v-switch
                          v-model="buscar_by_hash"
                          label="Buscar por Hash"
                          color="success"
                          @change="cambiaSwitchHash"
                        />
                      </div>
                      <v-switch
                        v-model="buscar_by_hash"
                        class="hidden-sm-and-up"
                        label="Buscar por Hash"
                        color="success"
                        @change="cambiaSwitchHash"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-switch
                        v-model="buscar_by_data"
                        label="Buscar por Datos de la Videoconsulta"
                        color="success"
                        @change="cambiaSwitchData"
                      />
                    </v-flex>
                  </v-layout>
                  <v-layout wrap>
                    <v-flex v-if="buscar_by_hash" xs12>
                      <v-text-field
                        v-model="model.hash"
                        class="form_text"
                        append-icon="mdi-code-tags"
                        name="hash"
                        label="Comprobar por Hash"
                        placeholder="Introduzca el Hash"
                        type="text"
                      />
                    </v-flex>
                    <v-flex v-if="buscar_by_data" xs12 sm6>
                      <v-text-field
                        v-model="model.idvideo"
                        append-icon="mdi-video"
                        style="width: 95%"
                        name="idvideo"
                        label="ID Videoconsulta"
                        placeholder="Identificador de la videoconsulta"
                        type="text"
                      />
                    </v-flex>
                    <v-flex v-if="buscar_by_data" xs12 sm6>
                      <v-select
                        id="navegador_select"
                        style="background-color: #fff"
                        :items="items"
                        placeholder="Seleccione un Navegador"
                        append-icon="mdi-web"
                        label="Navegador"
                      />
                    </v-flex>
                  </v-layout>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn :disabled="disabledButton" color="primary" :loading="loading" @click="buscarBloque"
                  >VALIDAR</v-btn
                >
                <v-spacer />
              </v-card-actions>
              <v-card-text v-if="loading">
                <div class="layout column align-center">
                  <img src="/static/blogchain.gif" alt="docline" width="150" />
                </div>
              </v-card-text>
              <v-card-text v-if="mostrar_alerta">
                <div slot="widget-content">
                  <v-alert v-model="mostrar_alerta" :type="alerta.color" :value="true" dismissible>
                    {{ alerta.texto }}
                  </v-alert>
                </div>
              </v-card-text>
              <v-card-text v-if="mostrar_datos">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-alert v-model="mostrar_datos" :type="alerta.color" :value="true">
                      {{ alerta.texto }}
                    </v-alert>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card v-if="mostrar_datos">
                <v-card-text>
                  <v-layout wrap>
                    <v-flex xs12 style="margin-top: 20px">
                      <p>
                        <span class="titulo_datos"
                          ><v-icon color="blue darken-3">mdi-receipt</v-icon>&nbsp;Transacción ID</span
                        ><br /><span class="texto_datos hidden-xs-only">{{ datos.txid }}</span
                        ><span class="texto_datos hidden-sm-and-up">{{ datos.txid_redux }}</span>
                      </p>
                    </v-flex>
                    <v-flex xs12 sm6 style="margin-top: 20px">
                      <p>
                        <span class="titulo_datos"
                          ><v-icon color="blue darken-3">mdi-view-comfy</v-icon>&nbsp;Block Height</span
                        ><br /><span class="texto_datos">{{ datos.blockNumber }}</span>
                      </p>
                    </v-flex>
                    <v-flex xs12 sm6 style="margin-top: 20px">
                      <p>
                        <span class="titulo_datos"
                          ><v-icon color="blue darken-3">mdi-calendar-range</v-icon>&nbsp;Fecha de Inclusión</span
                        ><br /><span class="texto_datos">{{ datos.fechaBlockchain }}</span>
                      </p>
                    </v-flex>
                    <v-flex xs12 sm6 style="margin-top: 20px">
                      <p>
                        <span class="titulo_datos"
                          ><v-icon color="blue darken-3">mdi-calendar-blank</v-icon>&nbsp;Fecha Videoconsulta</span
                        ><br /><span class="texto_datos">{{ datos.fecha }}</span>
                      </p>
                    </v-flex>
                    <v-flex xs12 sm4 style="margin-top: 20px">
                      <p>
                        <span class="titulo_datos"
                          ><v-icon color="blue darken-3">mdi-clock-outline</v-icon>&nbsp;Duración</span
                        ><br /><span class="texto_datos">{{ datos.duracion }} min.</span>
                      </p>
                    </v-flex>
                    <v-flex xs12 sm6 style="margin-top: 20px">
                      <p>
                        <span class="titulo_datos"><v-icon color="blue darken-3">mdi-web</v-icon>&nbsp;Navegador</span
                        ><br /><span class="texto_datos">{{ datos.sala.navegador }}</span>
                      </p>
                    </v-flex>
                    <v-flex xs12 sm6 class="hidden-xs-only">
                      <img id="bloque_img" src="/static/bloques.png" width="180px" />
                    </v-flex>
                  </v-layout>
                </v-card-text>
              </v-card>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
    <v-footer height="auto">
      <v-card flat tile class="blue darken-4 white--text text-center" style="width: 100%">
        <v-card-text>
          <v-btn icon class="mx-3 white--text" @click="abrirDireccion('https://www.facebook.com/Docline.es/')">
            <v-icon size="24px">fa-facebook</v-icon>
          </v-btn>
          <v-btn icon class="mx-3 white--text" @click="abrirDireccion('https://twitter.com/Docline_')">
            <v-icon size="24px">fa-twitter</v-icon>
          </v-btn>
          <v-btn
            icon
            class="mx-3 white--text"
            @click="abrirDireccion('https://www.youtube.com/channel/UCykR-w1g1eN-h_eoSOgxZww')"
          >
            <v-icon size="24px">fa-youtube</v-icon>
          </v-btn>
          <v-btn
            icon
            class="mx-3 white--text"
            @click="abrirDireccion('https://www.linkedin.com/company-beta/10984777/')"
          >
            <v-icon size="24px">fa-linkedin</v-icon>
          </v-btn>
        </v-card-text>
        <v-card-text class="white--text"> &copy;{{ anio }} — <strong>Docline</strong> </v-card-text>
      </v-card>
    </v-footer>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      :color="alerta.color"
      top="top"
      :multi-line="mode === 'multi-line'"
      :vertical="mode === 'vertical'"
    >
      <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
      >&nbsp;{{ alerta.texto }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { getDataBlockchain } from '../config/config';

export default {
  data: () => ({
    loading: false,
    model: {
      error: '',
      hash: '',
      idvideo: '',
      navegador: '',
      fecha: '',
      hora: '',
      duracion: '',
    },
    disabledButton: false,
    snackbar: false,
    mostrar_alerta: false,
    mostrar_datos: false,
    timeout: 6000,
    mode: '',
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
    },
    reset_titulo: '',
    btn_acceder_titulo: '',
    buscar_by_hash: true,
    buscar_by_data: false,
    items: ['Internet Explorer', 'Chrome', 'Firefox', 'Safari', 'Opera', 'Otro'],
    anio: 2019,
    datos: {},
  }),

  created() {
    this.reset_titulo = this.$t('message.remember');
    this.btn_acceder_titulo = this.$t('message.login');
    this.$vuetify.theme.primary = '#1976D2';
    this.$vuetify.theme.accent = '#82B1FF';
  },
  methods: {
    emptyForm() {
      this.model.hash = '';
      this.model.idvideo = '';
      this.model.navegador = '';
    },

    validarForm() {
      let res = true;
      if (this.buscar_by_hash && this.model.hash === '') {
        res = false;
      } else if (this.buscar_by_data && (this.model.idvideo === '' || this.model.navegador === '')) {
        res = false;
      }
      return res;
    },
    encriptar(input) {
      let output = '';
      for (let i = 0; i < input.length; i++) {
        output += input.charCodeAt(i).toString(16);
      }
      return output;
    },

    cambiaSwitchHash() {
      if (this.buscar_by_hash) {
        this.buscar_by_data = false;
      } else {
        this.buscar_by_data = true;
      }
    },

    cambiaSwitchData() {
      if (this.buscar_by_data) {
        this.buscar_by_hash = false;
      } else {
        this.buscar_by_hash = true;
      }
    },

    buscarBloque() {
      if (!this.loading && this.validarForm()) {
        this.loading = true;
        this.mostrar_datos = false;
        this.mostrar_alerta = false;
        const postData = {
          tipo: this.buscar_by_hash ? 'hash' : 'data',
          form: this.model,
        };
        this.$http.post(getDataBlockchain, postData).then(response => {
          const d = response.data;
          if (d.estado === 1) {
            this.mostrar_datos = true;
            this.alerta.color = 'success';
            this.alerta.texto = 'Videoconsulta localizada correctamente';
            this.alerta.icono = 'mdi-check';
            this.datos = d.videoconsulta;
          } else {
            this.mostrar_alerta = true;
            this.alerta.color = 'error';
            this.alerta.texto = 'No se ha encontrado ninguna videoconsulta realizada con los datos facilitados';
            this.alerta.icono = 'mdi-alert-circle';
          }
          this.emptyForm();
          this.loading = false;
        });
      }
    },
  },
};
</script>
<style scoped lang="css">
#blockchain {
  background-repeat: repeat;
  background-image: url('/static/faq_bg.png');
}
.separator {
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid #82b1ff;
  margin-top: 1em;
  padding: 0;
  margin-top: 20px;
}
.titulo_datos {
  font-size: 18px;
  color: #484db4;
}
.texto_datos {
  padding-left: 30px;
  font-weight: bold;
}
#bloque_img {
  opacity: 0.12;
  float: right;
  position: relative;
  margin-top: -80px;
}
</style>
